<template>
  <div class="lips">
    <Hero :heading-text="'Usne'" :heading-subtitle="'POTPUNO PRIRODNE I SENZUALNE USNE BEZ TRUDA NANOŠENJA RUŽA SVAKOG DANA'" :cta-text="'ZAKAŽITE TERMIN'" background-url="usnepocetna.jpg" background-url-mobile="prvoklasna-usluga-kontak.jpg"></Hero>
    <HeadlineCopy src1="usne.jpg" src2="royalartist.png" :heading-text="'Perfektno definisane usne'" :heading-subtitle="'Ukoliko želite da naglasite i poboljšate izgled usana, a da je rezultat sasvim prirodan, onda permanent make up pravi izbor za Vas. Metodom konturisanja i <br/>senčenjem, dobijate kompletno prirodne i senzualne usne bez truda <br/>nanošenja ruža svakog dana.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <SingleTextComponent :heading-text="'PRIRODNE, SENZUALNE I ZAVODLJIVE USNE SVAKI DAN'" background-url="slika.jpg"></SingleTextComponent>
    <HeadlineCopy src1="usne-3.jpg" :heading-text="'Intenzivan izgled'" :heading-subtitle="'U zavisnosti od vaše želje usne mogu biti diskretno ili intezivno osenčene, konturisane, kao i jačih ili slabijih boja. Uz korišćenje sofisticiranih tehnika i načinom unošenja pigmenta u kožu prirodan izgled Vam je zagarantovan.'"></HeadlineCopy>
    <Footer></Footer> 
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import HeadlineCopy from '@/components/HeadlineCopy.vue'
import SingleTextComponent from '@/components/SingleTextComponent.vue'
import Footer from '@/components/Footer.vue'



export default {
  name: 'Lips',
  components: {
    Hero,
    HeadlineCopy,
    SingleTextComponent,
    Footer
  }
}
</script>